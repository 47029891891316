var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.checkbox_checkbox_2QbUl {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n    width: 18px;\n    height: 18px;\n    border-radius: 4px;\n    transition: .2s;\n    background-color: var(--ui-white);\n    transition-property: background-color;\n    background-size: 100%;\n    border: 1px solid var(--ui-black-transparent);\n    outline: none;\n}\n\n.checkbox_checkbox_2QbUl:hover, .checkbox_checkbox_2QbUl:focus, .checkbox_checkbox_2QbUl:active, .checkbox_checkbox_2QbUl:checked {\n    border-color: var(--looks-secondary);\n}\n\n.checkbox_checkbox_2QbUl:focus, .checkbox_checkbox_2QbUl:active {\n    box-shadow: 0 0 0 3px var(--looks-transparent);\n}\n\n.checkbox_checkbox_2QbUl:checked {\n    background-color: var(--looks-secondary);\n    background-image: url(" + escape(require("./checkbox.svg")) + ");\n}\n", ""]);

// exports
exports.locals = {
	"checkbox": "checkbox_checkbox_2QbUl"
};